import React, { useState } from "react";
import { Navbar,Container, Nav } from "react-bootstrap";
import {useEffect } from "react";
import logo from '../../assets/img/logo.png'
import '../NavBar/NavBar.css'

export const NavBar = () => {
    const [activeLink,setActiveLink ] = useState('home')
    const [scrolled, setScrolled] = useState(false)
    useEffect(() => {
        const onScroll = () =>{
            if(window.scrollY > 50) {
                setScrolled(true);
            }else {
                setScrolled(false)
            }
        }   
         window.addEventListener("scroll", onScroll);
        return() => window.removeEventListener("scroll", onScroll);
    },[] )

    const onUpdateActiveLink = (value) => {
        setActiveLink(value)
    }
    
  return (
    <Navbar  expand="lg" className= {scrolled ? "scrolled": ""}>
      <Container>
        <Navbar.Brand href="#home">
            <img src={logo} alt="Logo"></img>
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">

            <Nav className="me-auto">
                <Nav.Link href="#home" className = {activeLink === 'home' ? 'active navbar-link': 'navbar-link'} onClick = {() => onUpdateActiveLink('home)')}>GreInvo</Nav.Link>
                <Nav.Link href="#features" className = {activeLink === 'features' ? 'active navbar-link': 'navbar-link'} onClick = {() => onUpdateActiveLink('skills)')}>Features</Nav.Link>
               
            </Nav> 
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
