import './App.css';
import {NavBar} from './components/NavBar/NavBar';
import {Features} from './components/ Features/ Features';
import {Product} from './components/Product/Product';
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import TermsAndCoditions from './pages/TermsAndConditions'
import { HouseDoor } from 'react-bootstrap-icons';
import NoPage from './pages/NoPage';
import Main from './pages/Main';
import Privacy from './pages/PrivacyPolicy';

function App() {
    return (
            <BrowserRouter>
                <Routes>
                    <Route index="index"element={<Main/>} ></Route>
                    <Route path ="/home"element={<Main/>} ></Route>
                    <Route path ="/terms&conditions"element={<TermsAndCoditions/>} ></Route>
                    <Route path ="/privacy&policy"element={<Privacy/>} ></Route>
                    <Route path= "*" element = {<NoPage></NoPage>}></Route>
                </Routes>
            </BrowserRouter>

   
    );
}

export default App;
