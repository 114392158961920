import React from "react";
import { Home } from "../components/Home/Home";
import { NavBar } from "../components/NavBar/NavBar";
import { Features } from "../components/ Features/ Features";
import { Product } from "../components/Product/Product";



export default function Main(){

    return(
        <div className="App">
            <NavBar></NavBar>
            <Home></Home>
            <Features></Features>
            <Product></Product>
        </div>
    )
}