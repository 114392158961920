import React from "react";
import "react-multi-carousel/lib/styles.css";
import Carousel from "react-multi-carousel";
import colorSharp from "../../assets/img/bg1.png";
import meter1 from "../../assets/img/g1.png";
import meter2 from "../../assets/img/g4.png";
import meter3 from "../../assets/img/g3.png";
import meter4 from "../../assets/img/g5.png";
import meter5 from "../../assets/img/g6.png";
import "../ Features/Features.css";

import {Container, Row, Col} from "react-bootstrap";

export const Features = () => {
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: {
                max: 4000,
                min: 3000
            },
            items: 5
        },
        desktop: {
            breakpoint: {
                max: 3000,
                min: 1024
            },
            items: 3
        },
        tablet: {
            breakpoint: {
                max: 1024,
                min: 464
            },
            items: 2
        },
        mobile: {
            breakpoint: {
                max: 464,
                min: 0
            },
            items: 1
        }
    };

    return (
        <section className="features" id="features">
            <Container>
                <Row className="align-items-center">
                    <Col xs={12} md={6} xl={6}>   
                            <div className="item1">
                                <img src={meter1} alt="Image"/>
                            </div>
                            <div className="item2">
                                <img src={meter2} alt="Image"/>
                             
                            </div>
                            <div className="item3">
                                <img src={meter3} alt="Image"/>
                              
                            </div>
                            <div className="item4">
                                <img src={meter4} alt="Image"/>
                            </div>
                            <div className="item5">
                                <img src={meter5} alt="Image"/>
                            </div>
                        
                    </Col>
                    <Col xs={12} md={6} xl={6}>
                        
                        <h2
                            data-reveal-self="rotate"
                            data-scrolled-into-view="true"
                            data-scrolled-past-view="true"
                            data-has-intersected="true">
                            <br></br>
                            <h1>User-friendly </h1>
                            <p> Our app is designed with ease of use in mind, ensuring that even those who are not tech-savvy can navigate it with ease.</p>
                            <br></br>
                            <h1>Intuitive </h1>
                            <p>We have created an intuitive interface that makes using our app feel like second nature, so you can get things done quickly and easily.</p>
                            <br></br>
                            <h1>Robust</h1>
                            <p> Our app is built to handle even the most complex tasks, with a wide range of features and tools that enable you to accomplish your goals efficiently.</p>
                            <br></br>
                            <h1>Innovative: </h1>
                            <p>Our app is innovative and cutting-edge, with features and tools that set it apart from the competition and help you achieve your goals in new and exciting ways.</p>
                            <br></br>
                            <h1>Free to use</h1>
                            <p> Our app is completely free to use, with no hidden fees or charges.</p>
                            <br></br>
                            <h1>Personalizable </h1>
                            <p> (coming soon) With our app, you can make it your own by customizing every aspect of its appearance and functionality to fit your preferences.</p>
                            <br></br>
                            <h1>Versatile </h1>
                            <p> (coming soon)Our app is versatile and can be used for a variety of purposes, making it a valuable tool for individuals, businesses, and organizations alike.</p>
                            <br></br>
                        </h2>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};
