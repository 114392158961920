import React from "react"
import {List} from "react-bootstrap-icons"
import './TermsConditions.css'

export default function TermsAndCoditions() {
    return (
        <section className="terms" id="terms">
            <div>
                <h2>
                <h1>Terms And Coditions
                </h1>
                <ul>
                    <p>GreInvo is a mobile application designed to help you calculate recycling prices and create invoices
                        quickly and easily. Before you begin using this app, please read these Terms and
                        Conditions ("Terms") carefully. By downloading and using this app, you agree to
                        be bound by these Terms. If you do not agree with these Terms, please do not use
                        the app.
                    </p>
                    <li>Data Loss:</li>
                    <p>The user acknowledges that data is saved locally on their device and the app
                        developer shall not be responsible for any loss of data due to device
                        malfunction, user error, or any other unforeseen circumstance. It is the user's
                        responsibility to ensure that data is regularly backed up to prevent loss.</p>
                    <li>Advertisements</li>
                    <p>This app may contain advertisements. The app developer is not responsible for the content of any advertisements or for any actions you take as a result of viewing an advertisement.</p>
                    <li>App Malfunction:</li>
                    <p>The app developer shall not be held responsible for any malfunction of the app due to unforeseen circumstances, including but not limited to, hardware or software malfunctions, system updates, or other technical issues. The user understands that the app may not function properly in certain situations and that the app developer will not be held responsible for any losses or damages resulting from such situations.</p>
                    <li>
                        Disclaimer of Warranties:</li>
                    <p>
                        The app developer makes no warranties or representations of any kind, express or
                        implied, including but not limited to, warranties of merchantability or fitness
                        for a particular purpose, with respect to the app or any related services. The
                        user understands that the app is provided "as is" and that the app developer
                        shall not be held responsible for any errors, omissions, or inaccuracies in the
                        app or related services.</p>
                    <li>Limitation of Liability:
                    </li>
                    <p>In no event shall the app developer be liable for any direct, indirect,
                        incidental, special, or consequential damages arising out of or in connection
                        with the use of the app or related services, even if the app developer has been
                        advised of the possibility of such damages. The user acknowledges that the app
                        developer's liability shall be limited to the amount paid for the app, and that
                        this limitation shall apply regardless of the form of action, whether in
                        contract, tort, or otherwise.</p>
                    <li>Indemnification:</li>
                    <p>The user agrees to indemnify and hold the app developer harmless from any
                        and all claims, damages, or expenses arising out of or in connection with the
                        user's use of the app or related services, including but not limited to, claims
                        for loss of data, breach of contract, or any other claims arising out of or
                        related to the user's use of the app.</p>
                    <br></br>
                    <p>These Terms and Conditions may be updated from time to time without notice to
                        you. By continuing to use the app after any changes to these Terms and
                        Conditions, you agree to be bound by the updated Terms and Conditions. If you
                        have any questions or concerns about these Terms and Conditions, please contact
                        us at greInvo@gmail.com</p>
                </ul>
                </h2>

            </div>
        </section>
    )
}