import React from "react"
import "../pages/PrivacyPolicy.css"
export default function Privacy() {
    return (
        <section className="privacy" id="privacy">
        <div>
            <h1>Privacy Policy</h1>

            <ul>
                <p>GreInvo is a mobile application designed to help you calculate recycling prices and create invoices
                        quickly and easily. We may display advertisements in the app provided by third-party ad networks. These ad networks may collect information about your device and usage data in order to serve relevant ads. We do not collect any personal information from you, and we do not share any information with these third-party ad networks. All data is saved locally on your device and is not transmitted to any external servers or third-party services. </p>
                <li>Data Collection</li>
                <p>Our app does not collect any personal information from you. All data is saved locally on your device and is not transmitted to any external servers or third-party services. However, the third-party ad networks that display ads in the app may collect information about your device and usage data in order to serve relevant ads. Please refer to the privacy policies of these third-party ad networks for more information</p>
                <li>Opt-Out of Ads:</li>
                <p>
You may opt-out of receiving personalized ads from our advertising partners by adjusting your device's advertising settings. Please note that opting out may still allow you to see ads, but they will not be tailored to your interests</p>
                <li>Use of Data</li>
                <p>The data you input into the app is used solely to calculate recycling prices and generate invoices. We do not use this information for any other purpose, and all this data is saved locally on your device. The third-party ad networks that display ads in the app may use information about your device and usage data in order to serve relevant ads. Please refer to the privacy policies of these third-party ad networks for more information</p>         
                <li>Security</li>
                <p>We take reasonable measures to protect your data from unauthorized access,
                    disclosure, or use. However, we cannot guarantee that your data will be
                    completely secure, and we do not take responsibility for any loss of data due to
                    device malfunction, user error, or any other unforeseen circumstance. It is your
                    responsibility to ensure that your data is regularly backed up to prevent loss.</p>
                <li>Changes to this Policy</li>
                <p>We may update this Policy from time to time. If we make any material changes,
                    we will notify you by email or by posting a notice in the app. Your continued
                    use of the app after any changes to this Policy indicates your acceptance of the
                    changes.</p>
                    <li>Contact Us</li>
                    <p>If you have any questions or concerns about this policy, please contact us at GreInvo@gmail.com. By using GreInvo, you agree to be bound by this Privacy Policy. If you do not agree with this Policy, please do not use the app.</p>
            </ul>
        </div>
        </section>
    )
}