import {Container, Row, Col} from "react-bootstrap";
import {ArrowRightCircle} from "react-bootstrap-icons";
import headerImg from "../../assets/img/logo.png";
import {useEffect, useState} from "react";
import '../Product/Product.css'

export const Product = () => {
    const toRotate = ["Create Invoices", "Calculate Salaries", "Calculate Products", "More"];
    const [isDeleting, setIsDeleting] = useState(false);
    const [loopNum, setloopNum] = useState(0);
    const [text, setText] = useState('');
    const period = 2000;
    const [delta, setDelta] = useState(300 - Math.random() * 100);

    useEffect(() => {
        let ticker = setInterval(() => {
            tick();
        }, delta)
        return() => {
            clearInterval(ticker)
        };
    }, [text])

    const tick = () => {
        let i = loopNum % toRotate.length;
        let fullText = toRotate[i];
        let updateText = isDeleting
            ? fullText.substring(0, text.length - 1)
            : fullText.substring(0, text.length + 1);

        setText(updateText);

        if (isDeleting) {
            setDelta(prevDelta => prevDelta / 2)
        }

        if (!isDeleting && updateText === fullText) {
            setIsDeleting(true);
            setDelta(period);

        } else if (isDeleting && updateText === '') {
            setIsDeleting(false);
            setloopNum(loopNum + 1);
            setDelta(300);
        }
    }

    return (
        <section className="product" id="product">
            <Container>

                <body>
                    <div class="footer-clean">
                        <footer>
                            <div class="container">
                                <div class="row justify-content-center">
                                    <div class="col-sm-4 col-md-3 item">
                                        <h3>About Us</h3>
                                        <ul>
                                            <li>
                                                <a href="#">Web design</a>
                                            </li>
                                            <li>
                                                <a href="#">iOS Development</a>
                                            </li>
                                            <li>
                                                <a href="#">Andoid Developer</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="col-sm-4 col-md-3 item">
                                        <h3>About GreInvo©</h3>
                                        <ul>
                                            <li>
                                                <a href="terms&conditions">Terms and Conditions</a>
                                            </li>
                                            <li>
                                                <a href="/privacy&policy">Privacy Policy</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="col-sm-4 col-md-3 item">
                                        <h3>Contact Us</h3>
                                        <ul>
                                            <li>
                                                <a href="#">greInvo@gmail.com</a>
                                            </li>
                                            <li>
                                                <a href="#">GreInvo© Intagram</a>
                                            </li>
                                            <li>
                                                <a href="#">GreInvo© twitter</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="col-lg-3 item social">
                                        <a href="#">
                                            <i class="icon ion-social-facebook"></i>
                                        </a>
                                        <a href="#">
                                            <i class="icon ion-social-twitter"></i>
                                        </a>
                                        <a href="#">
                                            <i class="icon ion-social-snapchat"></i>
                                        </a>
                                        <a href="#">
                                            <i class="icon ion-social-instagram"></i>
                                        </a>
                                        <p class="copyright">GreInvo© 2023</p>
                                    </div>
                                </div>
                            </div>
                        </footer>
                    </div>

                </body>
            </Container>
        </section>
    )
}