import { Container, Row, Col } from "react-bootstrap";
import { ArrowRightCircle } from "react-bootstrap-icons";
import headerImg from "../../assets/img/g1.png";
import appdownload from "../../assets/img/appd.svg";
import sourceCode from "../../assets/img/github-mark.svg"
import { useEffect, useState } from "react";
import "../Home/Home.css";

export const Home = () => {
  const toRotate = [
    "Create Invoices",
    "Calculate Salaries",
    "Calculate Products",
    "More",
  ];
  const [isDeleting, setIsDeleting] = useState(false);
  const [loopNum, setloopNum] = useState(0);
  const [text, setText] = useState("");
  const period = 2000;
  const [delta, setDelta] = useState(300 - Math.random() * 100);

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);
    return () => {
      clearInterval(ticker);
    };
  }, [text]);

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updateText = isDeleting
      ? fullText.substring(0, text.length - 1)
      : fullText.substring(0, text.length + 1);

    setText(updateText);

    if (isDeleting) {
      setDelta((prevDelta) => prevDelta / 2);
    }

    if (!isDeleting && updateText === fullText) {
      setIsDeleting(true);
      setDelta(period);
    } else if (isDeleting && updateText === "") {
      setIsDeleting(false);
      setloopNum(loopNum + 1);
      setDelta(300);
    }
  };

  const handleDownloadButton = () => {
    window.location.href = 'https://apps.apple.com/us/app/greinvo/id6448755643';
  };

  return (
    <section className="home" id="home">
      <Container>
        <Row className="align-items-center">
          <Col xs={12} md={6} xl={7}>
            <span className="tagline">GreInvo</span>
            <h2 data-reveal-self="rotate" data-scrolled-into-view="true" data-scrolled-past-view="true" data-has-intersected="true">
              <span data-sl-variant="get-your-business-online">the ultimate solution for recycling calculation</span>
              </h2>
            <div className="home-options">
              <a href="https://apps.apple.com/us/app/greinvo/id6448755643" target="_blank" rel="noopener noreferrer">
                <img src={appdownload} alt="" />
              </a>
            <a role="button" href="https://github.com/fcamas/GreInvo" className="btn-light" target="_blank" rel="noopener noreferrer">
                <img src={sourceCode} alt="Source Code" width="16" height="16" />
                Source Code 
                 </a>
            </div>
          </Col>
         <Col xs={12} md={6} xl={5}>
            <img src={headerImg} alt="Header Image"></img>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

<div className="home-options">
              <img src={appdownload} alt=""></img>
            </div>
