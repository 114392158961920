import React from "react";



export default function NoPage(){
    return(
        <h1>NO Page Found</h1>

    )
        
    
}